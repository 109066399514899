import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import * as style from "./landing.module.css"
import { getIsApp, getIsMobile, getJson, getThumb } from "utils"
import Footer from "components/common/footer"
import floating from "images/floating.png"
import api from "apis"
import Logo from "components/tools/logo"
import LogIn from "images/logIn.png"

import dompurify from "dompurify"
import Seo from "components/seo"
import dayjs from "dayjs"

import jQuery from "../../static/jquery"

// const token = getJson("access_token") ? true : false
const IndexPage = () => {
  const isMobile = getIsMobile()
  const [post, setPost] = useState({})
  const [isApp, setisApp] = useState(false)

  useEffect(() => {
    window.$ = window.jQuery = jQuery
    const isApp = getIsApp()
    setisApp(isApp)
    if (isApp) navigate("/login")
    ;(async () => {
      const landing = await api.getLanding()
      if (landing) setPost(landing)
      console.log(landing)
    })()
    // window.onpopstate = function () {
    //   if (location.pathname === "/") {
    //     navigate(-1)
    //   }
    // }
    // return window.removeEventListener("popstate", window.onpopstate)
  }, [])

  if (isMobile)
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: post?.bodyText,
        }}
      />
    )

  return isApp ? null : (
    <div className={style.con}>
      <Seo />
      <div className={style.box} key={dayjs()}>
        {isApp && (
          <div className={style.header}>
            <div>
              <div className={style.logo}>
                <Logo w={141.65} h={31.5} white={true} />
              </div>

              <div className={style.login} onClick={() => navigate("/login")}>
                <div>
                  <div>
                    <img src={LogIn} />
                    로그인
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isApp && !isMobile ? (
          <div
            style={{
              position: "absolute",
              left: "90%",
              width: "100px",
              height: "37px",
            }}
            key={dayjs.unix() + 2}
          >
            <div
              className={style.login}
              style={{
                width: "100px",
                height: "40px",
              }}
              onClick={() => navigate("/login")}
            >
              <div>
                <div
                  style={{
                    fontSize: "15px",
                  }}
                >
                  <img src={LogIn} />
                  로그인
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={style.body}>
          {!isApp && (
            <img
              src={
                "https://peach-bookcases-dist-www.s3.ap-northeast-2.amazonaws.com/landingPage.png"
              }
              style={{ width: "100vw" }}
              key={dayjs.unix() + 1}
            />
          )}
        </div>
        {isMobile ? null : (
          <div
            className={style.float}
            onClick={() => navigate("/member/signUp/terms")}
          >
            <img
              src={
                !isApp
                  ? "https://peach-bookcases-dist-www.s3.ap-northeast-2.amazonaws.com/landingFloating.png"
                  : floating
              }
            />
            {isApp && <div className={style.signUp}>가입도 쉬운 피치서가</div>}
          </div>
        )}
        {}
      </div>
    </div>
  )
}

export default IndexPage
