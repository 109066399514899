import * as React from "react"
import { Helmet } from "react-helmet"

function Seo() {
  const contents = "피치서가 : 느린학습자를 위한 디지털 학습 플랫폼"
  const description =
    "다양한 수준의 학습자를 고려한 주제중심의 쉬운 글, 그림단어, 질문거리, 음성지원을 제공합니다"

  return (
    <Helmet
      htmlAttributes={{
        lang: "ko",
      }}
    >
      <title>{contents}</title>
      <meta name="title" content={contents} />
      <meta name="description" content={description} />
      <meta name="keywords" content={contents} />
      <meta
        name="google-site-verification"
        content="zb_Vj5cX_wouZLIVF5jSJces9tB2A19oX4EvamwibSw"
      />
      <meta
        name="naver-site-verification"
        content="60082036a41b0f8ed29750ffc51a3b7748381ea8"
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={contents} />
      <meta property="og:site_name" content={contents} />
      <meta property="og:description" content={description} />

      <meta name="twitter:title" content={contents} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

export default Seo
